import store from '@/store'

export const type = 'button'
export const logDomainInteraction = async ({
  name,
  searchParams = null,
  slideStartTime = null,

}) => {

  const interaction = {
    type: type,
    name: name,
  }
  if (searchParams && name === 'domain-search') {
    interaction['searched'] = searchParams['searched']
    interaction['searchResults'] = cleanSearchResults(searchParams['results'])
  } else if (slideStartTime && name === 'slide_interaction') {
    interaction['action'] = 'visit'
    interaction['timeOnSlide'] = calculateTimeSpentOnSlide(slideStartTime)
  }

  try {
    await store.dispatch('stageline/logSlideInteraction', interaction, { root: true })
  } catch (_ex) {
    // Suppressing error
  }
}

const calculateTimeSpentOnSlide = (startTime) => {
  const endTime = Date.now()
  const difference = endTime - startTime
  const totalSeconds = Math.floor(difference / 1000)
  const minutes = Math.floor(totalSeconds / 60)
  const seconds = totalSeconds % 60

  return `${minutes} minutes, ${seconds} seconds`
}

const cleanSearchResults = (results) => {
  if (results.length === 0) return 'none'

  return results.map(result => result.domain)
}
